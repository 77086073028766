var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),(_vm.isLoadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h6 primary--text",domProps:{"textContent":_vm._s('Reporte inmediato de incidente y/o falla de control')}}),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s('HSEQ-S&SO1-F-9')}})]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoadingDraft,"disabled":!_vm.validateHeader() || _vm.isLoadingSave},on:{"click":function($event){return _vm.sendIncident('DRAFT')}}},[_vm._v(" Guardar como borrador ")])],1)],1),_c('v-card',{staticClass:"my-10",attrs:{"elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.incidentFormat)?_c('v-stepper',{staticClass:"pb-0",attrs:{"elevation":"0","vertical":"","value":_vm.steps.current},model:{value:(_vm.steps.current),callback:function ($$v) {_vm.$set(_vm.steps, "current", $$v)},expression:"steps.current"}},[_c('v-stepper-step',{attrs:{"complete":"","step":"1","edit-icon":_vm.validateHeader()
									? 'mdi-check'
									: 'mdi-alert-circle-outline',"editable":"","color":_vm.steps.current == 1
									? 'primary'
									: _vm.validateHeader()
									? 'success'
									: 'error'}},[_vm._v(" Inicio ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[(_vm.incidentFormat && _vm.incidentFormat.headers)?_c('form-header',{attrs:{"headers":_vm.incidentFormat.headers}}):_vm._e(),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = 2}}},[_vm._v(" Siguiente ")])],1),_c('v-stepper-step',{attrs:{"complete":"","step":"2","edit-icon":_vm.validateInvolved()
									? 'mdi-check'
									: 'mdi-alert-circle-outline',"editable":"","color":_vm.steps.current == 2
									? 'primary'
									: _vm.validateInvolved()
									? 'success'
									: 'error'}},[_vm._v(" Involucrados ")]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('involved-form',{model:{value:(_vm.involved),callback:function ($$v) {_vm.involved=$$v},expression:"involved"}}),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = 3}}},[_vm._v(" Siguiente ")])],1),_vm._l((_vm.bodies),function(body,index){return [_c('v-stepper-step',{key:index + 'vss',attrs:{"complete":"","editable":"","step":index + 3,"edit-icon":_vm.validateBody(body)
										? 'mdi-check'
										: 'mdi-alert-circle-outline',"color":_vm.steps.current == index + 3
										? 'primary'
										: _vm.validateBody(body)
										? 'success'
										: 'error'}},[_vm._v(" "+_vm._s(_vm.textTransform(body.group || body.label))+" ")]),_c('v-stepper-content',{key:index + 'vsc',attrs:{"step":index + 3}},[_c('v-card',{staticClass:"pb-8",attrs:{"elevation":0}},[_c('v-card-text',[(body.group)?_c('div',{},[_vm._l((body.items),function(input,inputi){return [_c('div',{key:inputi + 'input',staticClass:"mb-4"},[_c('p',[_vm._v(" "+_vm._s(_vm.textTransform(input.label))+" ")]),_vm._l((JSON.parse(
															input.values
														) || []),function(item,itemi){return [_c('v-checkbox',{key:itemi + 'chk',attrs:{"label":item.label,"value":item.value,"multiple":"","dense":""},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}),(
															(
																input.value || ''
															).includes('Otros')
														)?_c('v-text-field',{attrs:{"outlined":""},model:{value:(input.otherText),callback:function ($$v) {_vm.$set(input, "otherText", $$v)},expression:"input.otherText"}}):_vm._e(),_c('hr')],2)]})],2):_c('div',[_c('cbody-textarea',{attrs:{"label":body.label,"note":body.note,"disabledButton":index == _vm.bodies.length - 1},model:{value:(body.value),callback:function ($$v) {_vm.$set(body, "value", $$v)},expression:"body.value"}}),(index == _vm.bodies.length - 1)?_c('div',{staticClass:"py-8 px-4"},[_c('input-file',{model:{value:(_vm.evidences),callback:function ($$v) {_vm.evidences=$$v},expression:"evidences"}}),_vm._l((_vm.listEvidences),function(evidence,evdindex){return [_c('div',{key:evdindex},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('img',{staticClass:"d-block",attrs:{"height":"auto","width":"100px","max-height":"100px","max-width":"100","src":evidence.urlImage}})]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-textarea',{attrs:{"rows":"2","outlined":"","label":"Descripción de la imagen","hide-details":""},model:{value:(
																		evidence.description
																	),callback:function ($$v) {_vm.$set(evidence, "description", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tevidence.description\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{on:{"click":function($event){return _vm.deleteEvidence(
																			evidence
																		)}}},[_vm._v(" mdi-delete ")])],1)],1)],1)]})],2):_vm._e()],1)])],1),(_vm.incidentFormat && index < _vm.bodies.length - 1)?_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = index + 4}}},[_vm._v(" Siguiente ")]):_vm._e()],1)]})],2):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"px-6"},[_c('validation-alert',{attrs:{"validationErrors":_vm.validationErrors || []}})],1)])],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"pa-8 d-flex justify-end flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.isLoadingDraft,"disabled":!_vm.validateHeader() || _vm.isLoadingSave},on:{"click":function($event){return _vm.sendIncident('DRAFT')}}},[_vm._v(" Guardar como borrador ")]),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"green","loading":_vm.isLoadingSave,"disabled":!_vm.isCompletedInspectons || _vm.isLoadingDraft},on:{"click":function($event){return _vm.sendIncident('PENDING')}}},[_vm._v(" Finalizar ")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }